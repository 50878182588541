// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-approval-disclosure-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/approval-disclosure.js" /* webpackChunkName: "component---src-pages-approval-disclosure-js" */),
  "component---src-pages-bppe-disclosures-cosmetician-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/bppe-disclosures/cosmetician.js" /* webpackChunkName: "component---src-pages-bppe-disclosures-cosmetician-js" */),
  "component---src-pages-bppe-disclosures-cosmetology-instructor-trainee-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/bppe-disclosures/cosmetology-instructor-trainee.js" /* webpackChunkName: "component---src-pages-bppe-disclosures-cosmetology-instructor-trainee-js" */),
  "component---src-pages-bppe-disclosures-cosmetology-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/bppe-disclosures/cosmetology.js" /* webpackChunkName: "component---src-pages-bppe-disclosures-cosmetology-js" */),
  "component---src-pages-bppe-disclosures-index-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/bppe-disclosures/index.js" /* webpackChunkName: "component---src-pages-bppe-disclosures-index-js" */),
  "component---src-pages-bppe-disclosures-manicuring-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/bppe-disclosures/manicuring.js" /* webpackChunkName: "component---src-pages-bppe-disclosures-manicuring-js" */),
  "component---src-pages-campus-security-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/campus-security.js" /* webpackChunkName: "component---src-pages-campus-security-js" */),
  "component---src-pages-cosmetician-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/cosmetician.js" /* webpackChunkName: "component---src-pages-cosmetician-js" */),
  "component---src-pages-cosmetology-instructor-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/cosmetology-instructor.js" /* webpackChunkName: "component---src-pages-cosmetology-instructor-js" */),
  "component---src-pages-cosmetology-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/cosmetology.js" /* webpackChunkName: "component---src-pages-cosmetology-js" */),
  "component---src-pages-enrollment-application-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/enrollment-application.js" /* webpackChunkName: "component---src-pages-enrollment-application-js" */),
  "component---src-pages-enrollment-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/enrollment.js" /* webpackChunkName: "component---src-pages-enrollment-js" */),
  "component---src-pages-financial-aid-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/financial-aid.js" /* webpackChunkName: "component---src-pages-financial-aid-js" */),
  "component---src-pages-gainful-employment-cosmetician-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/gainful-employment/cosmetician.js" /* webpackChunkName: "component---src-pages-gainful-employment-cosmetician-js" */),
  "component---src-pages-gainful-employment-cosmetology-instructor-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/gainful-employment/cosmetology-instructor.js" /* webpackChunkName: "component---src-pages-gainful-employment-cosmetology-instructor-js" */),
  "component---src-pages-gainful-employment-cosmetology-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/gainful-employment/cosmetology.js" /* webpackChunkName: "component---src-pages-gainful-employment-cosmetology-js" */),
  "component---src-pages-gainful-employment-manicuring-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/gainful-employment/manicuring.js" /* webpackChunkName: "component---src-pages-gainful-employment-manicuring-js" */),
  "component---src-pages-guest-services-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/guest-services.js" /* webpackChunkName: "component---src-pages-guest-services-js" */),
  "component---src-pages-index-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institution-section-1-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/institution-section-1.js" /* webpackChunkName: "component---src-pages-institution-section-1-js" */),
  "component---src-pages-manicuring-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/manicuring.js" /* webpackChunkName: "component---src-pages-manicuring-js" */),
  "component---src-pages-naccas-annual-report-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/naccas-annual-report.js" /* webpackChunkName: "component---src-pages-naccas-annual-report-js" */),
  "component---src-pages-net-price-calculator-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/net-price-calculator.js" /* webpackChunkName: "component---src-pages-net-price-calculator-js" */),
  "component---src-pages-performance-fact-sheet-cosmetician-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/performance-fact-sheet/cosmetician.js" /* webpackChunkName: "component---src-pages-performance-fact-sheet-cosmetician-js" */),
  "component---src-pages-performance-fact-sheet-cosmetology-instructor-trainee-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/performance-fact-sheet/cosmetology-instructor-trainee.js" /* webpackChunkName: "component---src-pages-performance-fact-sheet-cosmetology-instructor-trainee-js" */),
  "component---src-pages-performance-fact-sheet-cosmetology-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/performance-fact-sheet/cosmetology.js" /* webpackChunkName: "component---src-pages-performance-fact-sheet-cosmetology-js" */),
  "component---src-pages-performance-fact-sheet-manicuring-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/performance-fact-sheet/manicuring.js" /* webpackChunkName: "component---src-pages-performance-fact-sheet-manicuring-js" */),
  "component---src-pages-performance-fact-sheet-performance-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/performance-fact-sheet/performance.js" /* webpackChunkName: "component---src-pages-performance-fact-sheet-performance-js" */),
  "component---src-pages-pre-enrollment-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/pre-enrollment.js" /* webpackChunkName: "component---src-pages-pre-enrollment-js" */),
  "component---src-pages-school-catalog-2022-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/school-catalog-2022.js" /* webpackChunkName: "component---src-pages-school-catalog-2022-js" */),
  "component---src-pages-school-catalog-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/school-catalog.js" /* webpackChunkName: "component---src-pages-school-catalog-js" */),
  "component---src-pages-school-disclosures-js": () => import("/media/Data/Code/dev.thanhlecollege.com-gatsby/src/pages/school-disclosures.js" /* webpackChunkName: "component---src-pages-school-disclosures-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/media/Data/Code/dev.thanhlecollege.com-gatsby/.cache/data.json")

